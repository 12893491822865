<template>
    <div class="container teamReport">
        <div class="row header">
            <i @click="goBack" class="fa fa-angle-left back-button"></i>
            <p class="attendance" style="margin-left: 32% !important;">Setting Center</p>
        </div>
        <div class="setting__profile">
            
        </div>
    </div>
 </template>
 <script>
 import axios from "axios";
 export default {
     name: "SettingView",
     data() {
         return {
            inputType: 'password',
            inputType2: 'password',
             user: {
                 username: null,
                 password: null,
                 confirm_password: null,
                 invite_code: null
             }
         }
 
     },
    //  beforeCreate: function () {
    //      if (localStorage.getItem('username') != null)
    //          this.$router.push({ name: 'Home' });
    //  },
     created() {
         // URL se query parameter ko extract karna
         const urlParams = new URLSearchParams(window.location.search);
         const r_code = urlParams.get('r_code');
         if (r_code) {
            this.user.invite_code = r_code;
         }
      },
     methods: {
      toggleInputType() {
        this.inputType = this.inputType === 'password' ? 'text' : 'password';
        },
      toggleInputType2() {
        this.inputType2 = this.inputType2 === 'password' ? 'text' : 'password';
        },
         pop(message) {
             document.getElementById("snackbar").innerHTML = message;
             document.getElementById("snackbar").style.display = "";
             setTimeout(function () { document.getElementById("snackbar").style.display = "none"; }, 3000);
         },
         loading() {
             document.getElementById("loading").style.display = "";
             setTimeout(function () { document.getElementById("loading").style.display = "none"; }, 500);
         },
         onLogin() {
            if (this.user.username == null || this.user.password == null || this.user.confirm_password == null || this.user.invite_code == null) {
                    if (this.user.invite_code == null) {
                        this.pop("Invitation Code is requied")
                    } if (this.user.confirm_password == null) {
                        this.pop("Confirm Password is requied")
                    } if (this.user.password == null) {
                        this.pop("Password is requied")
                    } if (this.user.username == null) {
                        this.pop("Mobile number is requied")
                    } 
                 
             }
             else if(this.user.password !== this.user.confirm_password)
             {
               this.pop("Confirm password dosn't match with password")
             }
             else if(this.user.password.length < 6)
             {
               this.pop("Password must be at least 6 characters long.")
             }
             else {
                 var data = new FormData();
                 data.append("password", this.user.password);
                 data.append("username", this.user.username);
                 data.append("invite_code", this.user.invite_code);
                 axios
                     .post(
                         "https://manager.allinone4u.live/trova/src/api/user_api_tcgames.php?register",
                         data
                     )
                     .then(res => {
                         if (res.data.error==true) {
                           //   this.loading();
                             localStorage.setItem('username', this.user.username);
                             console.log("Success", res.data.message);
                             this.pop(res.data.message);
                             setTimeout(() => { this.$router.push('home'); }, 100);
                         } else {
                             
                             console.log("Error", res.data);
                             this.pop(res.data.message);
                         }
                     })
                     .catch(err => {
                         console.log("Error", err);
                     });
             }
         },
         redirect() {
             console.log(this.$store.state.name);
             //this.$router.push({ name: 'Search' });
         },
 
     }
 
 
 
 
 }
</script>
<style>
.setting__profile{
    background: var(--main-color);
    height: 8rem;
    border-bottom-left-radius: 20%;
    border-bottom-right-radius: 20%;
    width: 107.6%;
    margin-left: -3.8%;
}

@media screen and (max-width:500px) {
    .setting__profile{
    width: 109%;
    margin-left: -4.5%;
}

}
</style>