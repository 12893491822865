<template>
    <div data-v-36cc3380="" class="Recharge__box" style="--f13b4d11-currentFontFamily: 'Roboto', 'Inter', sans-serif;">
   <div data-v-36cc3380="" class="Recharge__container">
      <div data-v-12a80a3e="" data-v-36cc3380="" class="navbar white">
         <div data-v-12a80a3e="" class="navbar-fixed">
            <div data-v-12a80a3e="" class="navbar__content text-white">
               <div data-v-12a80a3e="" class="navbar__content-left">
                  <i data-v-12a80a3e="" @click="goBack" class="fa fa-angle-left van-icon van-icon-arrow-left" style="font-size:25px;">
                     <!----><!----><!---->
                  </i>
               </div>
               <div data-v-12a80a3e="" class="navbar__content-center">
                  
                  <div data-v-12a80a3e="" class="navbar__content-title nav-recharge" style="opacity: 0;">Deposit</div>
               </div>
               <div data-v-12a80a3e="" class="navbar__content-right">
                  <div data-v-36cc3380="" class="title nav-ttt-recharge" @click="$router.push('rechargehistory')">Collection record</div>
               </div>
            </div>
         </div>
      </div>
      <div class="dailySignIn__container">
         <div data-v-d7213382="" class="task-banner">
            <div data-v-d7213382="">
               <p data-v-d7213382="" class="banner-title">Activity Award</p>
            </div>
         </div>
            <div data-v-d7213382="" class="task-panel">
               <!--v-if-->
               <div data-v-d7213382="" class="task-item">
                  <div data-v-d7213382="" class="task-item-header">
                     <div data-v-d7213382="" class="hearder-status day">Daily mission</div>
                     <span data-v-d7213382="" class="headerGray">Unfinished</span>
                  </div>
                  <div data-v-d7213382="" class="task-item-type">
                     <div data-v-d7213382="" class="type-title weeklyType3">
                        <svg data-v-d7213382="" class="svg-icon icon-weeklyType3">
                           <use xlink:href="#icon-weeklyType3"></use>
                        </svg>
                        <div data-v-d7213382="">Daily betting bonus </div>
                     </div>
                     <div data-v-d7213382="" class="type-tip" v-if="userdetails.exp<100000">{{ userdetails.exp }}/100000</div>
                     <div data-v-d7213382="" class="type-tip" v-else>100000/100000</div>
                  </div>
                  <div data-v-d7213382="" class="task-item-description">Daily betting bonus </div>
                  <div data-v-d7213382="" class="task-item-bottom">
                     <div data-v-d7213382="">Award amount</div>
                     <div data-v-d7213382="" class="bottom-title">
                        <svg data-v-d7213382="" class="svg-icon icon-activityWallet">
                           <use xlink:href="#icon-activityWallet"></use>
                        </svg>
                        <span data-v-d7213382="">₹1,000.00</span>
                     </div>
                  </div>
                  <div data-v-d7213382="" class="btn btnOther status1">to complete</div>
               </div>
               <div data-v-d7213382="" class="task-item">
                  <div data-v-d7213382="" class="task-item-header">
                     <div data-v-d7213382="" class="hearder-status day">Daily mission</div>
                     <span data-v-d7213382="" class="headerGray">Unfinished</span>
                  </div>
                  <div data-v-d7213382="" class="task-item-type">
                     <div data-v-d7213382="" class="type-title weeklyType3">
                        <svg data-v-d7213382="" class="svg-icon icon-weeklyType3">
                           <use xlink:href="#icon-weeklyType3"></use>
                        </svg>
                        <div data-v-d7213382="">Daily betting bonus </div>
                     </div>
                     <div data-v-d7213382="" class="type-tip" v-if="userdetails.exp<50000">{{ userdetails.exp }}/50000</div>
                     <div data-v-d7213382="" class="type-tip" v-else>50000/50000</div>
                  </div>
                  <div data-v-d7213382="" class="task-item-description">Daily betting bonus </div>
                  <div data-v-d7213382="" class="task-item-bottom">
                     <div data-v-d7213382="">Award amount</div>
                     <div data-v-d7213382="" class="bottom-title">
                        <svg data-v-d7213382="" class="svg-icon icon-activityWallet">
                           <use xlink:href="#icon-activityWallet"></use>
                        </svg>
                        <!-- <img v-lazy="getIcons('wallet', 'balance')" /> --><span data-v-d7213382="">₹500.00</span>
                     </div>
                  </div>
                  <div data-v-d7213382="" class="btn btnOther status1">to complete</div>
               </div>
               <div data-v-d7213382="" class="task-item">
                  <div data-v-d7213382="" class="task-item-header">
                     <div data-v-d7213382="" class="hearder-status day">Daily mission</div>
                     <span data-v-d7213382="" class="headerGray">Unfinished</span>
                  </div>
                  <div data-v-d7213382="" class="task-item-type">
                     <div data-v-d7213382="" class="type-title weeklyType3">
                        <svg data-v-d7213382="" class="svg-icon icon-weeklyType3">
                           <use xlink:href="#icon-weeklyType3"></use>
                        </svg>
                        <div data-v-d7213382="">Daily betting bonus </div>
                     </div>
                     <div data-v-d7213382="" class="type-tip" v-if="userdetails.exp<5000">{{ userdetails.exp }}/5000</div>
                     <div data-v-d7213382="" class="type-tip" v-else>5000/5000</div>
                  </div>
                  <div data-v-d7213382="" class="task-item-description">Daily betting bonus </div>
                  <div data-v-d7213382="" class="task-item-bottom">
                     <div data-v-d7213382="">Award amount</div>
                     <div data-v-d7213382="" class="bottom-title">
                        <svg data-v-d7213382="" class="svg-icon icon-activityWallet">
                           <use xlink:href="#icon-activityWallet"></use>
                        </svg>
                        <!-- <img v-lazy="getIcons('wallet', 'balance')" /> --><span data-v-d7213382="">₹40.00</span>
                     </div>
                  </div>
                  <div data-v-d7213382="" class="btn btnOther status1">to complete</div>
               </div>
               <div data-v-d7213382="" class="task-item">
                  <div data-v-d7213382="" class="task-item-header">
                     <div data-v-d7213382="" class="hearder-status day">Daily mission</div>
                     <span data-v-d7213382="" class="headerGray">Unfinished</span>
                  </div>
                  <div data-v-d7213382="" class="task-item-type">
                     <div data-v-d7213382="" class="type-title weeklyType3">
                        <svg data-v-d7213382="" class="svg-icon icon-weeklyType3">
                           <use xlink:href="#icon-weeklyType3"></use>
                        </svg>
                        <div data-v-d7213382="">Daily betting bonus </div>
                     </div>
                     <div data-v-d7213382="" class="type-tip" v-if="userdetails.exp<500">{{ userdetails.exp }}/500</div>
                     <div data-v-d7213382="" class="type-tip" v-else>500/500</div>
                  </div>
                  <div data-v-d7213382="" class="task-item-description">Daily betting bonus </div>
                  <div data-v-d7213382="" class="task-item-bottom">
                     <div data-v-d7213382="">Award amount</div>
                     <div data-v-d7213382="" class="bottom-title">
                        <svg data-v-d7213382="" class="svg-icon icon-activityWallet">
                           <use xlink:href="#icon-activityWallet"></use>
                        </svg>
                        <!-- <img v-lazy="getIcons('wallet', 'balance')" /> --><span data-v-d7213382="">₹5.00</span>
                     </div>
                  </div>
                  <div data-v-d7213382="" class="btn btnOther status1">to complete</div>
               </div>
            </div>
         </div>
      
   </div>
</div>
</template>
<script>
import axios from 'axios';
    export default {
        name: 'DailyTaskView',
        data() {
        return {
          inputValue: '',
          userdetails: {
               username: null,
               balance: null,
               page: 1,
               total22: 0
           },
           rechargerecord: {

            },
        };
      },
      beforeCreate: function () {
        if (localStorage.getItem('username') == null)
            this.$router.push('Login');
      },
      created: function () {
       this.getUserdetails();
      },
      methods: {
        updateInput(value) {
          this.inputValue = value;
        },
         goBack() {
            window.history.back();
         },
         getUserdetails() {
           this.userdetails.username = localStorage.getItem('username');
           axios.get('https://manager.allinone4u.live/trova/src/api/me_api_tcgames.php?action=getuserinfo&user=' + this.userdetails.username).then((res) => {
               this.Users = res.data.user_Data;
               console.log(this.Users);
               this.userdetails.balance = this.Users[0].balance;
               
           }).catch((err) => {
               console.log(err);
           })

           axios.get('https://manager.allinone4u.live/trova/src/api/me_api_tcgames.php?action=my_exp&user=' + this.userdetails.username).then((res) => {
               this.Exp = res.data.user_Data;
               console.log(this.Exp);
               this.userdetails.exp = this.Exp[0].amount;
            }).catch((err) => {
                console.log(err);
            })

           axios.get('https://manager.allinone4u.live/trova/src/api/bet.php?action=rechargerecord&user=' + this.userdetails.username + '&page1=' + this.userdetails.page).then((res) => {
                this.rechargerecord = res.data;
                console.log(this.rechargerecord);
            }).catch((err) => {
                console.log(err);
            })
        },
         proceed() {
               this.username = localStorage.getItem('username');
               if (document.getElementById("amount").value > 999) {
                  var link = "https://manager.allinone4u.live/trova/src/api/pay.php?user="+this.username+"&am="
                  // var link = "https://powerindia.in/tcppay/index.php?uid="+this.username+"&amount="
                  var amount = document.getElementById("amount").value;
                  var payurl = link.concat(amount);
                  window.open(payurl, "_blank");
               }
               else {
                  var x = document.getElementById("snackbar");
                  x.style.display = "";
                  setTimeout(function e() {  x.style.display="none"; }, 1000);
               }
            }
      }
    }
</script>
<style>
.van-toast {
position: fixed;
top: 50%;
left: 50%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
box-sizing: content-box;
width: 88px;
max-width: 70%;
min-height: 88px;
padding: 16px;
color: #fff;
font-size: 14px;
line-height: 20px;
white-space: pre-wrap;
text-align: center;
word-wrap: break-word;
background-color: rgba(50,50,51,.88);
border-radius: 8px;
transform: translate3d(-50%,-50%,0)
}

.van-toast--unclickable {
overflow: hidden
}

.van-toast--unclickable * {
pointer-events: none
}

.van-toast--html,.van-toast--text {
width: -webkit-fit-content;
width: -moz-fit-content;
width: fit-content;
min-width: 96px;
min-height: 0;
padding: 8px 12px
}

.van-toast--html .van-toast__text,.van-toast--text .van-toast__text {
margin-top: 0
}

.van-toast--top {
top: 50px
}

.van-toast--bottom {
top: auto;
bottom: 50px
}

.van-toast__icon {
font-size: 40px
}

.van-toast__loading {
padding: 4px;
color: #fff
}

.van-toast__text {
margin-top: 8px
}
</style>