<template>
    <div data-v-36cc3380="" class="Recharge__box" style="--f13b4d11-currentFontFamily: 'Roboto', 'Inter', sans-serif;">
   <div data-v-36cc3380="" class="Recharge__container">
      <div data-v-12a80a3e="" data-v-36cc3380="" class="navbar white">
         <div data-v-12a80a3e="" class="navbar-fixed">
            <div data-v-12a80a3e="" class="navbar__content text-white">
               <div data-v-12a80a3e="" class="navbar__content-left">
                  <i data-v-12a80a3e="" @click="goBack" class="fa fa-angle-left van-icon van-icon-arrow-left" style="font-size:25px;">
                     <!----><!----><!---->
                  </i>
               </div>
               <div data-v-12a80a3e="" class="navbar__content-center">
                  <!--v-if-->
                  <div data-v-12a80a3e="" class="navbar__content-title nav-recharge-history">Withdraw Hisory</div>
               </div>
            </div>
         </div>
      </div>
      <div data-v-9f5f4114="" data-v-36cc3380="" class="record__main" payid="2">
         <!--v-if-->
         <div data-v-9f5f4114="" style="margin-bottom: 50px">
            <div data-v-9f5f4114="" class="record__main-info" v-for="row in rechargerecord" :key="row.id">
               <div data-v-9f5f4114="" class="record__main-info__title flex_between">
                  <div data-v-9f5f4114="" class="recharge_text" style="background: var(--main-color)">Withdraw</div>
                  <div data-v-9f5f4114="" class="flex_between">
                     <div data-v-9f5f4114="" class="success text-uppercase" style="letter-spacing:2px; font-weight: 700;" v-if="row.status==='Withddrawing'">Completed</div>
                     <div data-v-9f5f4114="" class="rechargeFail text-uppercase" style="letter-spacing:2px; font-weight: 700;" v-if="row.status===''">Rejected</div>
                     <div data-v-9f5f4114="" class="text-warning text-uppercase" style="letter-spacing:2px; font-weight: 700;" v-if="row.status==='Applying'">To Be process</div>
                  </div>
               </div>
               <div data-v-9f5f4114="" class="record__main-info__money item flex_between"><span data-v-9f5f4114="" style="font-size: 0.9rem">Balance</span><span data-v-9f5f4114="">₹{{ Number(row.withdraw).toFixed(2)}}</span></div>
               <div data-v-9f5f4114="" class="record__main-info__type item flex_between"><span data-v-9f5f4114="" style="font-size: 0.9rem">Type</span><span data-v-9f5f4114="">BANK CARD</span></div>
               <div data-v-9f5f4114="" class="record__main-info__time item flex_between"><span data-v-9f5f4114="" style="font-size: 0.9rem">Time</span><span data-v-9f5f4114="">{{ row.created_at }}</span></div>
               <div data-v-9f5f4114="" style="margin-top: -65px;" class="record__main-info__orderNumber item flex_between">
                  <span data-v-9f5f4114="" style="font-size: 0.9rem">Order number</span>
                  <div data-v-9f5f4114="">
                     <span data-v-9f5f4114="" style="font-size: 0.9rem">{{ row.rand }}</span>
                     <svg data-v-9f5f4114="" class="svg-icon icon-copy" width="20px" alt="">
                        <use xlink:href="#icon-copy"></use>
                     </svg>
                  </div>
               </div>
            </div>
        </div>
      </div>
      <div id="snackbar" class="van-toast van-toast--middle van-toast--text" style="z-index: 2009;display:none ">
         <div class="van-toast__text">Minimum Recharge 1000</div>
      </div>
   </div>
</div>
</template>
<script>
   import axios from 'axios';
    export default {
        name: 'WithdrawHistoryView',
        data(){
            return{
               userdetails: {
                  username: null,
                  total: 0,
               },
               rechargerecord: {

               },
               betbox: {
                  page: 1,
                  total:10,
               },
            }
        },
        beforeCreate: function () {
          if (localStorage.getItem('username') == null)
              this.$router.push('Login');
         },
         created: function() {
            this.getUserdetails();
         },
        methods:{
         goBack() {
            window.history.back();
         },
         up() {
                this.betbox.page = this.betbox.page + 1;
                this.getUserdetails();
            },
            down() {
                if (this.betbox.page > 1) {
                    this.betbox.page = this.betbox.page - 1;
                    this.getUserdetails();
                } else {
                    this.pop("First Page Now!")
                }
    
            },
         getUserdetails(){
            this.userdetails.username = localStorage.getItem('username');
            axios.get('https://manager.allinone4u.live/trova/src/api/bet.php?action=withdrawrecord&user=' + this.userdetails.username + '&page1=' + this.betbox.page).then((res) => {
                this.rechargerecord = res.data;
                console.log(this.rechargerecord);
            }).catch((err) => {
                console.log(err);
            })
         },
        }
    }
</script>
<style>
.fixed-bottom{
    width: 33.33%;
    margin-left: 35%;
}
@media screen and (max-width: 500px) {
   .fixed-bottom{
    width: 100%;
    margin-left: 0;
}  
}
</style>