<template>
   <div class="container teamReport">
        <div class="row header">
            <i @click="goBack" class="fa fa-angle-left back-button"></i>
            <p>Subordinate Data</p>
        </div>
        <div class="row">
            <div class="search-button">
                <input type="text" maxlength="25" placeholder="Search subordinate UID">
                <button><i class="fa fa-search"></i></button>
            </div>
            <div class="date-tier">
                <div class="tier">
                    <select class="form-control">
                        <option value="">All</option>
                    </select>
                </div>
                <div class="tier">
                    <input type="date" />
                </div>
            </div>
            <div class="all-details">
                <div class="content-flex">
                    <b class="deposit_number">{{ userdetails.deposit_number }}</b>
                    <b class="deposit_amount">{{ userdetails.deposit_amount }}</b>
                </div>
                <div class="content-flex">
                    <p class="deposit_number_p">Deposit number</p>
                    <p class="deposit_amount_p">Deposit Amount</p>
                </div>
                <div class="content-flex" style="margin-top: -30px">
                    <b class="deposit_number">{{ userdetails.batters }}</b>
                    <b class="deposit_amount">{{ userdetails.bet_amount }}</b>
                </div>
                <div class="content-flex">
                    <p class="number_of_batters_p">Number of batters</p>
                    <p class="total_bet_p">Total bet</p>
                </div>
                <div class="content-flex" style="margin-top: -30px">
                    <b class="deposit_number">{{ userdetails.first_deposit }}</b>
                    <b class="deposit_amount" v-if="userdetails.first_deposit_amount>0">{{ userdetails.first_deposit_amount }}</b>
                    <b class="deposit_amount" v-else>0</b>
                </div>
                <div class="content-flex">
                    <p class="first_deposit_number_p">Number of peoples <br> make first deposit</p>
                    <p class="first_deposit_amount_p">First deposit amount</p>
                </div>
            </div>
            <div v-if="loading" class="preloader">
                <div class="spinner-wrapper">
                    <div class="spinner"></div>
                    <img src="../assets/png/logo.png" alt="Loading" class="loader-image"/>
                </div>
            </div>
            <br>
            <div class="user__detail___box" v-for="rows in History" :key="rows.id">
                <div style="display: flex;">
                    <p class="uid">UID:{{ rows.usercode }}</p>
                    <img src="../assets/png/copy-icon-fead2b7d.png" alt="">
                </div>
                <hr style="width:100%;">
                <div class="flex">
                    <p class="float-left pl-2">Level</p>
                    <p class="right">{{ rows.level }}</p>
                </div>
                <div class="flex">
                    <p class="float-left pl-2">Deposit amount</p>
                    <p class="right_amount">{{ rows.deposit }}</p>
                </div>
                <div class="flex">
                    <p class="float-left pl-2">Bet amount</p>
                    <p class="right_bet_amount">{{ rows.bet_amount }}</p>
                </div>
                <div class="flex">
                    <p class="float-left pl-2">Commission</p>
                    <p class="right_bet_amount">{{ rows.amount }}</p>
                </div>
                <div class="flex">
                    <p class="float-left pl-2">Time</p>
                    <p class="right_bet_amount">{{ rows.date }}</p>
                </div>
            </div>
            <!-- <div class="user__detail___box">
                <div style="display: flex;">
                    <p class="uid">UID:324201</p>
                    <img src="../assets/png/copy-icon-fead2b7d.png" alt="">
                </div>
                <hr style="width:100%;">
                <div class="flex">
                    <p class="float-left pl-2">Level</p>
                    <p class="right">4</p>
                </div>
                <div class="flex">
                    <p class="float-left pl-2">Deposit amount</p>
                    <p class="right_amount">100004</p>
                </div>
                <div class="flex">
                    <p class="float-left pl-2">Bet amount</p>
                    <p class="right_bet_amount">4</p>
                </div>
                <div class="flex">
                    <p class="float-left pl-2">Commission</p>
                    <p class="right_bet_amount">0.04</p>
                </div>
                <div class="flex">
                    <p class="float-left pl-2">Time</p>
                    <p class="right_bet_amount">2024-07-28</p>
                </div>
            </div> -->
            <br>
        </div>
   </div>
</template>
<script>
import axios from 'axios';
    export default {
        name: 'TeamReportView',
         data() {
            return{
                inputType: 'text',
                selectedDate: '',
                loading: false,
                History: [],
                userdetails: {
                    deposit_number: '0',
                    usercode: null,
                    deposit_amount: '0',
                    first_deposit: '0',
                    batters: '0',
                    bet_amount: '0',
                    first_deposit_amount: '0',
                }
            }
         },
         beforeCreate: function () {
          if (localStorage.getItem('username') == null)
              this.$router.push('Login');
         },
         computed: {
            isDateSelected() {
            return this.selectedDate !== null;
            }
        },
        created: function () {
            this.getUserdetails();
        },
        methods:{
            goBack() {
                window.history.back();
            },
            showDatePicker() {
            this.inputType = 'date';
            },
            getUserdetails() {
                this.loading = true;
            this.userdetails.username = localStorage.getItem('username');
            axios.get('https://manager.allinone4u.live/trova/src/api/subordinate_data.php?action=deposit_number&username=' + this.userdetails.username).then((res) => {
                this.DepositNumber = res.data.user_Data;
                console.log(this.DepositNumber);
                this.userdetails.deposit_number = this.DepositNumber[0].total_deposit_user;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.loading = false;
            });
            axios.get('https://manager.allinone4u.live/trova/src/api/subordinate_data.php?action=deposit_amount&username=' + this.userdetails.username).then((res) => {
                this.DepositAmount = res.data.user_Data;
                console.log(this.DepositAmount);
                this.userdetails.deposit_amount = this.DepositAmount[0].deposit_amount;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.loading = false;
            });
            axios.get('https://manager.allinone4u.live/trova/src/api/subordinate_data.php?action=first_deposit&username=' + this.userdetails.username).then((res) => {
                this.FirstDeposit = res.data.user_Data;
                console.log(this.FirstDeposit);
                this.userdetails.first_deposit = this.FirstDeposit[0].first_deposit;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.loading = false;
            });
            axios.get('https://manager.allinone4u.live/trova/src/api/subordinate_data.php?action=batters&username=' + this.userdetails.username).then((res) => {
                this.Batters = res.data.user_Data;
                console.log(this.Batters);
                this.userdetails.batters = this.Batters[0].batters;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.loading = false;
            });
            axios.get('https://manager.allinone4u.live/trova/src/api/subordinate_data.php?action=bet_amount&username=' + this.userdetails.username).then((res) => {
                this.BetAmount = res.data.user_Data;
                console.log(this.BetAmount);
                this.userdetails.bet_amount = this.BetAmount[0].bet_amount;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.loading = false;
            });
            axios.get('https://manager.allinone4u.live/trova/src/api/subordinate_data.php?action=first_deposit_amount&username=' + this.userdetails.username).then((res) => {
                this.FirstAmount = res.data.user_Data;
                console.log(this.FirstAmount);
                this.userdetails.first_deposit_amount = this.FirstAmount[0].first_deposit_amount;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.loading = false;
            });
            axios.get('https://manager.allinone4u.live/trova/src/api/subordinate_data.php?action=my_details&username=' + this.userdetails.username).then((res) => {
                this.MyDetails = res.data.user_Data;
                console.log(this.MyDetails);
                this.userdetails.usercode = this.MyDetails[0].usercode;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.loading = false;
            });
            axios.get('https://manager.allinone4u.live/trova/src/api/transactionApi.php?action=history&username=' + this.userdetails.username).then((res) => {
                this.History = res.data;
                console.log(this.History);
            }).catch((err) => {
                console.log(err);
            })
            },
        }
    }
</script>
<style>
[type="date"] {
  background:#fff url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)  97% 50% no-repeat ;
}
[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}
.preloader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.preloader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.spinner {
  width: 100px; /* Size of the spinner */
  height: 100px; /* Size of the spinner */
  border: 8px solid #fff; /* Light grey border */
  border-left: 8px solid var(--main-color); /* Blue border on the left */
  border-radius: 50%;
  position: relative; /* Make sure the spinner's child elements are positioned relative to it */
  animation: spin 1s linear infinite;
  
}

.loader-image {
  width: 80px; /* Size of the image */
  height: 80px; /* Size of the image */
  border-radius: 50%;
  
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the image */
  z-index: 1; /* Ensure the image is above the spinner */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>