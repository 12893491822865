<template>
    <div data-v-36cc3380="" class="Recharge__box" style="--f13b4d11-currentFontFamily: 'Roboto', 'Inter', sans-serif;">
       <div data-v-36cc3380="" class="Recharge__container">
          <div data-v-12a80a3e="" data-v-36cc3380="" class="navbar white">
             <div data-v-12a80a3e="" class="navbar-fixed">
                <div data-v-12a80a3e="" class="navbar__content">
                   <div data-v-12a80a3e="" class="navbar__content-left">
                      <i data-v-12a80a3e="" @click="goBack" class="fa fa-angle-left van-icon van-icon-arrow-left" style="font-size:25px;">
                         <!----><!----><!---->
                      </i>
                   </div>
                   <div data-v-12a80a3e="" class="navbar__content-center">
                      <!--v-if-->
                      <div data-v-12a80a3e="" class="navbar__content-title nav-recharge-history">Game History</div>
                   </div>
                </div>
                <div class="game__History_games">
                  <div class="game__Category" @click="setTab('tab1')" :class="{ active: currentTab === 'tab1' }">
                     <img src="../assets/png/lotterycategory_202401021647469isn.png" alt="">
                     <p>WinGo</p>
                  </div>
                  <div class="game__Category" @click="setTab('tab2')" :class="{ active: currentTab === 'tab2' }">
                     <img src="../assets/png/lotterycategory_20231215033454m1k3.8727b968.png" alt="">
                     <p>TrxWin</p>
                  </div>
                </div>
                <div data-v-f84b843f="" data-v-61888f52="" class="empty__container empty"><svg data-v-f84b843f="" class="svg-icon icon-empty" style="opacity:0.1; margin-top: 60px"><use xlink:href="#icon-empty"></use></svg><p data-v-f84b843f="" class="text-center">No data</p></div>
             </div>
          </div>
       </div>
    </div>
 </template>
 <script>
    import axios from 'axios';
     export default {
         name: 'GameHistoryView',
         data(){
             return{
                currentTab: 'tab1',
                userdetails: {
                   username: null,
                   total: 0,
                },
                rechargerecord: {
    
                },
                betbox: {
                   page: 1,
                   total:10,
                },
             }
         },
         beforeCreate: function () {
           if (localStorage.getItem('username') == null)
               this.$router.push('Login');
          },
          created: function() {
             this.getUserdetails();
          },
         methods:{
          goBack() {
             window.history.back();
          },
          getUserdetails(){
             this.userdetails.username = localStorage.getItem('username');
             axios.get('https://manager.allinone4u.live/trova/src/api/game_statics.php?action=today&user=' + this.userdetails.username).then((res) => {
                this.Users = res.data.user_Data;
                console.log(this.Users);
                this.userdetails.total_bet = this.Users[0].total_bet;
                this.userdetails.total_num = this.Users[2].total_num;
                this.userdetails.total_win = this.Users[1].total_win;
             }).catch((err) => {
                 console.log(err);
             })
             axios.get('https://manager.allinone4u.live/trova/src/api/game_statics.php?action=yesterday&user=' + this.userdetails.username).then((res) => {
                this.Users = res.data.user_Data;
                console.log(this.Users);
                this.userdetails.total_bet1 = this.Users[0].total_bet1;
                this.userdetails.total_num1 = this.Users[2].total_num1;
                this.userdetails.total_win1 = this.Users[1].total_win1;
             }).catch((err) => {
                 console.log(err);
             })
             axios.get('https://manager.allinone4u.live/trova/src/api/game_statics.php?action=week&user=' + this.userdetails.username).then((res) => {
                this.Users = res.data.user_Data;
                console.log(this.Users);
                this.userdetails.total_bet2 = this.Users[0].total_bet2;
                this.userdetails.total_num2 = this.Users[2].total_num2;
                this.userdetails.total_win2 = this.Users[1].total_win2;
             }).catch((err) => {
                 console.log(err);
             })
             axios.get('https://manager.allinone4u.live/trova/src/api/game_statics.php?action=month&user=' + this.userdetails.username).then((res) => {
                this.Users = res.data.user_Data;
                console.log(this.Users);
                this.userdetails.total_bet3 = this.Users[0].total_bet3;
                this.userdetails.total_num3 = this.Users[2].total_num3;
                this.userdetails.total_win3 = this.Users[1].total_win3;
             }).catch((err) => {
                 console.log(err);
             })
          },
          setTab(tab) {
                  this.currentTab = tab;
               },
         }
     }
 </script>