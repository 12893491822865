<template>
    <div data-v-36cc3380="" class="Recharge__box" style="--f13b4d11-currentFontFamily: 'Roboto', 'Inter', sans-serif;">
   <div data-v-36cc3380="" class="Recharge__container">
      <div data-v-12a80a3e="" data-v-36cc3380="" class="navbar white">
         <div data-v-12a80a3e="" class="navbar-fixed">
            <div data-v-12a80a3e="" class="navbar__content">
               <div data-v-12a80a3e="" class="navbar__content-left">
                  <i data-v-12a80a3e="" @click="goBack" class="fa fa-angle-left van-icon van-icon-arrow-left" style="font-size:25px;">
                     <!----><!----><!---->
                  </i>
               </div>
               <div data-v-12a80a3e="" class="navbar__content-center">
                  
                  <div data-v-12a80a3e="" class="navbar__content-title nav-recharge">Withdraw</div>
               </div>
               <div data-v-12a80a3e="" class="navbar__content-right">
                  <div data-v-36cc3380="" class="title nav-ttt-recharge" @click="$router.push('WithdrawHistory')">Withdraw history</div>
               </div>
            </div>
         </div>
      </div>
      <!-- 余额组件 -->
      <div data-v-98c90f53="" data-v-36cc3380="" class="balanceAssets">
         <div data-v-98c90f53="" class="balanceAssets__header">
            <div data-v-98c90f53="" class="balanceAssets__header__left"><img data-v-98c90f53="" src="../assets/png/balance-e39ce400.png"> Available Balance</div>
         </div>
         <div data-v-98c90f53="" class="balanceAssets__main">
            <p data-v-98c90f53="">₹{{ Number(userdetails.balance).toFixed(2) }}</p>
            <img data-v-98c90f53="" src="../assets/png/refresh-8e0efe26.png" alt="">
         </div>
         <!-- <div class="balanceAssets__tip">
            <img :src="getIconsPublic('wallet/recharge', 'cip')" alt="" />
            </div> -->
      </div>
      <!-- 支付方式选择菜单 -->
      <div data-v-4f3d8608="" data-v-36cc3380="">
         
         <div data-v-4f3d8608="" class="Recharge__container-tabcard">
            <div data-v-4f3d8608="" class="Recharge__container-tabcard__items " @click="$router.push('Withdrawal')">
               <div data-v-4f3d8608="" class="centers">
                  
                  <div data-v-4f3d8608="" class="Recharge__container-tabcard__top"><img data-v-4f3d8608="" class="img" src="../assets/png/WithBeforeImgIcon2_20240203165318nx9g.png" alt=""></div>
                  <div data-v-4f3d8608="" class="Recharge__container-tabcard__bot">
                     Bank Card <!-- <span v-if="item.maxRechargeRifts && item.maxRechargeRifts > 0">{{ item?.maxRechargeRifts * 100 }}%</span> -->
                  </div>
               </div>
            </div>
            <div data-v-4f3d8608="" class="Recharge__container-tabcard__items active">
               <div data-v-4f3d8608="" class="centers">
                  
                  <div data-v-4f3d8608="" class="Recharge__container-tabcard__top"><img data-v-4f3d8608="" class="img" src="../assets/png/payNameIcon_202401241504247a6g.png" alt=""></div>
                  <div data-v-4f3d8608="" class="Recharge__container-tabcard__bot">
                     USDT <!-- <span v-if="item.maxRechargeRifts && item.maxRechargeRifts > 0">{{ item?.maxRechargeRifts * 100 }}%</span> -->
                  </div>
               </div>
            </div>
         </div>
         
      </div>
      <div data-v-9e03166f="" class="Recharge__content">
         <div data-v-9e03166f="" class="Recharge__content-paymoney boxStyle">
            <div class="wihdraw__bank_add" v-if="userdetails.usdt_ac==='1111111111'">
                <div data-v-ef5c8333="" class="addWithdrawType-top" @click="$router.push('AddUsdtAddress')">
                  <!-- @click="$router.push('AddBankAccount')" -->
                    <img data-v-ef5c8333="" src="../assets/png/add-1ad7f3f5.png">
                    <span data-v-ef5c8333="">Add address</span>
                </div>
            </div>

            <div class="wihdraw__bank_add mt-2">
                <div data-v-80a607a5="" class="bankInfo" v-if="userdetails.usdt_ac!=='1111111111'">
                    <div data-v-80a607a5="" class="bankInfoItem type1">
                        <div data-v-80a607a5="">
                            <svg data-v-80a607a5="" class="svg-icon icon-1">
                                <use xlink:href="#icon-1"></use>
                            </svg>
                            <span data-v-80a607a5="">TRC</span>
                        </div>
                        <div data-v-80a607a5=""><span data-v-80a607a5=""></span><span data-v-80a607a5="">{{ userdetails.usdt_ac }}****</span></div>
                        <i data-v-80a607a5="" class="van-badge__wrapper van-icon van-icon-arrow fa fa-angle-right">
                            <!----><!----><!---->
                        </i>
                    </div>
                    <!--v-if--><!--v-if-->
                </div>
            </div>
            <div class="wihdraw__bank_add mt-2">
               <form>
                <div data-v-cb5583fe="" class="explain" style="">
                    <div data-v-cb5583fe="" class="input">
                        <div data-v-cb5583fe="" class="place-div">₹</div>
                        <input data-v-cb5583fe="" placeholder="Please enter the amount" @input="filterInput" v-model="inputValue" class="inp">
                    </div>
                    <div data-v-cb5583fe="" class="input">
                        <div data-v-cb5583fe="" class="place-div">$</div>
                        <input data-v-cb5583fe="" readonly placeholder="Amount in USDT" :value="input2" id="amount" class="inp">
                    </div>
                    <!--v-if-->
                    
                    <div data-v-cb5583fe="" class="balance bank">
                        <div data-v-cb5583fe="">
                            <span data-v-cb5583fe="">
                                Withdrawable balance 
                                <h6 data-v-cb5583fe="" class="yellow">₹{{ Number(userdetails.balance).toFixed(2) }}</h6>
                            </span>
                            <input data-v-cb5583fe="" type="button" value="All">
                        </div>
                        <div data-v-cb5583fe="">
                            <span data-v-cb5583fe="">Withdrawal amount received</span>
                            <div data-v-cb5583fe="" class="rightD"><span data-v-cb5583fe="" class="yellow">₹{{ Math.round(inputValue).toFixed(2) }}</span></div>
                        </div>
                    </div>
                    <div data-v-80a607a5="" class="recycleBtnD mt-3" v-if="userdetails.balance>110 && userdetails.usdt_ac!=1111111111 && 3>userdetails.total_with_today && (userdetails.total_recharge-userdetails.total_bet)<='0'">
                        <button data-v-80a607a5="" @click.prevent="withDraw()" class="recycleBtn Wbtn">Withdraw</button>
                    </div>
                </div>
               </form>
               <div id="snackbar" class="van-toast van-toast--middle van-toast--text" style="z-index: 2009;display:none ">
                <div class="van-toast__text">success</div>
            </div>
            </div>
         </div>
      </div>
      <div data-v-76eb7f31="" data-v-80a607a5="" class="Recharge__container-intro">
        <div data-v-76eb7f31="" class="br">
            <!--v-if-->
            <p data-v-76eb7f31="" v-if="(userdetails.total_recharge-userdetails.total_bet)>'0'">Need to bet <span data-v-470caa86="" class="red" >₹{{ Number(userdetails.total_recharge-userdetails.total_bet) }}</span> to be able to withdraw</p>
            <p data-v-76eb7f31="" v-else>Need to bet <span data-v-470caa86="" class="red" >₹0.00</span> to be able to withdraw</p>
            <p data-v-76eb7f31="">Withdraw time <span data-v-76eb7f31="" class="red">00:00-23:59</span></p>
            <p data-v-76eb7f31="" v-if="'3'>userdetails.total_with_today">Inday Remaining Withdrawal Times<span data-v-76eb7f31="" class="red">{{ 3-userdetails.total_with_today }}</span></p>
            <p data-v-76eb7f31="" v-if="'3'<=userdetails.total_with_today">Inday Remaining Withdrawal Times<span data-v-76eb7f31="" class="red">0</span></p>
            <p data-v-76eb7f31="">Withdrawal amount range <span data-v-76eb7f31="" class="red">₹110.00-₹500,000.00</span></p>
            <!--v-if--><!--v-if--><!--v-if-->
            <p data-v-76eb7f31="">Please confirm your beneficial account information before withdrawing. If your information is incorrect, our company will not be liable for the amount of loss</p>
            <p data-v-76eb7f31="">If your beneficial information is incorrect, please contact customer service</p>
        </div>
      </div>
      <div data-v-9f5f4114="" data-v-36cc3380="" class="record__main" payid="2">
         <div data-v-9f5f4114="" class="record__main-title">
            <svg data-v-9f5f4114="" class="svg-icon icon-historyHead">
               <use xlink:href="#icon-historyHead"></use>
            </svg>
            <span data-v-9f5f4114="">Withdrawal history</span>
         </div>
         <div data-v-9f5f4114="">
            <div data-v-9f5f4114="" class="record__main-info" v-for="row in rechargerecord" :key="row.id">
               <div data-v-9f5f4114="" class="record__main-info__title flex_between">
                  <div data-v-9f5f4114="" class="recharge_text" style="background: var(--main-color)">Withdraw</div>
                  <div data-v-9f5f4114="" class="flex_between">
                     <div data-v-9f5f4114="" class="success text-uppercase" style="letter-spacing:2px; font-weight: 700;" v-if="row.status==='Withddrawing'">Completed</div>
                     <div data-v-9f5f4114="" class="rechargeFail text-uppercase" style="letter-spacing:2px; font-weight: 700;" v-if="row.status===''">Rejected</div>
                     <div data-v-9f5f4114="" class="text-warning text-uppercase" style="letter-spacing:2px; font-weight: 700;" v-if="row.status==='Applying'">To Be process</div>
                  </div>
               </div>
               <div data-v-9f5f4114="" class="record__main-info__money item flex_between"><span data-v-9f5f4114="" style="font-size: 0.9rem">Balance</span><span data-v-9f5f4114="">₹{{ Number(row.withdraw).toFixed(2)}}</span></div>
               <div data-v-9f5f4114="" class="record__main-info__type item flex_between"><span data-v-9f5f4114="" style="font-size: 0.9rem">Type</span><span data-v-9f5f4114="">BANK CARD</span></div>
               <div data-v-9f5f4114="" class="record__main-info__time item flex_between"><span data-v-9f5f4114="" style="font-size: 0.9rem">Time</span><span data-v-9f5f4114="">{{ row.created_at }}</span></div>
               <div data-v-9f5f4114="" style="margin-top: -65px;" class="record__main-info__orderNumber item flex_between">
                  <span data-v-9f5f4114="" style="font-size: 0.9rem">Order number</span>
                  <div data-v-9f5f4114="">
                     <span data-v-9f5f4114="" style="font-size: 0.9rem">{{ row.rand }}</span>
                     <svg data-v-9f5f4114="" class="svg-icon icon-copy" width="20px" alt="">
                        <use xlink:href="#icon-copy"></use>
                     </svg>
                  </div>
               </div>
            </div>
            
            <div data-v-9f5f4114="" @click="$router.push('WithdrawHistory')" class="record__main-more">All history</div>
         </div>
      </div>
   </div>
</div>
</template>
<script>
import axios from 'axios';
    export default {
        name: 'UsdtWithdrawView',
        data() {
        return {
          inputValue: null,
          userdetails: {
               username: null,
               balance: null,
               bank: null,
               page: 1,
               total22: 0,
           },
        };
      },
      beforeCreate: function () {
        if (localStorage.getItem('username') == null)
            this.$router.push('Login');
      },
      created: function () {
       this.getUserdetails();
      },
      computed: {
            input2() {
            return this.inputValue / 92;
            },
        },
      methods: {
        pop(message) {
         document.getElementById("snackbar").innerHTML = message;
         document.getElementById("snackbar").style.display = "";
         setTimeout(function () { document.getElementById("snackbar").style.display = "none"; }, 1000);
       },
       filterInput(event) {
        const regex = /[^0-9]/g;
        event.target.value = event.target.value.replace(regex, '');
        this.inputValue = event.target.value;
        },
       getUserdetails() {
           this.userdetails.username = localStorage.getItem('username');
           axios.get('https://manager.allinone4u.live/trova/src/api/me_api_tcgames.php?action=getuserinfo&user=' + this.userdetails.username).then((res) => {
               this.Users = res.data.user_Data;
               console.log(this.Users);
               this.userdetails.balance = this.Users[0].balance;
               
           }).catch((err) => {
               console.log(err);
           })
           axios.get('https://manager.allinone4u.live/trova/src/api/me_api_tcgames.php?action=get_withdraw_info&user=' + this.userdetails.username).then((res) => {
               this.Withdraw = res.data.user_Data;
               console.log(this.Withdraw);
               this.userdetails.total_recharge = this.Withdraw[0].total;
               this.userdetails.total_bet = this.Withdraw[1].totalbetting;
               this.userdetails.total_with_today = this.Withdraw[2].number_with;
               this.userdetails.bank = this.Withdraw[3].bank;
               this.userdetails.usdt_ac = this.Withdraw[5].usdt_ac;
               this.userdetails.bankname = this.Withdraw[4].bankname;
           }).catch((err) => {
               console.log(err);
           })
           axios.get('https://manager.allinone4u.live/trova/src/api/bet.php?action=withdrawrecord&user=' + this.userdetails.username + '&page1=' + this.userdetails.page).then((res) => {
                this.rechargerecord = res.data;
                console.log(this.rechargerecord);
            }).catch((err) => {
                console.log(err);
            })
        },
        withDraw(){
            if(this.inputValue === null){
               this.pop("Enter withdrawal amount");
            }
            else if(this.inputValue<920)
            {
               this.pop("Minimum withdrawal 10 USDT");
            }
            else if(this.userdetails.balance < 920)
            {
               this.pop("Insufficient balance");
            }
            else{
               axios.get('https://manager.allinone4u.live/trova/src/api/bet.php?action=withdrawal&user=' + this.userdetails.username + '&with=usdt&amount=' + this.inputValue).then((res) => {
                   this.result = res.data[0];
                   this.pop(this.result.status);
                   if (this.result.status == "Success") {
                     //   this.loading();
                       this.getUserdetails();
                       this.inputValue = null;
                       setTimeout(() => { this.$router.push("/WithdrawHistory"); }, 600);
                   }
                   console.log(this.result);
               }).catch((err) => {
                   console.log(err);
               })
            }
        },
        updateInput(value) {
          this.inputValue = value;
        },
         goBack() {
            window.history.back();
         },
         
      }
    }
</script>
<style>
.van-toast {
position: fixed;
top: 50%;
left: 50%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
box-sizing: content-box;
width: 88px;
max-width: 70%;
min-height: 88px;
padding: 16px;
color: #fff;
font-size: 14px;
line-height: 20px;
white-space: pre-wrap;
text-align: center;
word-wrap: break-word;
background-color: rgba(50,50,51,.88);
border-radius: 8px;
transform: translate3d(-50%,-50%,0)
}

.van-toast--unclickable {
overflow: hidden
}

.van-toast--unclickable * {
pointer-events: none
}

.van-toast--html,.van-toast--text {
width: -webkit-fit-content;
width: -moz-fit-content;
width: fit-content;
min-width: 96px;
min-height: 0;
padding: 8px 12px
}

.van-toast--html .van-toast__text,.van-toast--text .van-toast__text {
margin-top: 0
}

.van-toast--top {
top: 50px
}

.van-toast--bottom {
top: auto;
bottom: 50px
}

.van-toast__icon {
font-size: 40px
}

.van-toast__loading {
padding: 4px;
color: #fff
}

.van-toast__text {
margin-top: 8px
}
.Wbtn{
    width: 250% !important;
    height: 30px;
    font-size: 14px !important;
    font-weight: 700;
    border-radius: 25px !important;
}
@media screen and (max-width: 500px) {
    .Wbtn{
        width: 200% !important;
        
    }
}
</style>