<template>
   <div class="top-nav">
      <img src="../assets/png/logo.png" class="logo" alt="">
      <div class="pl-5 ml-5">
         <img src="../assets/png/message.png" class="message" alt="">
         <a href="https://manager.allinone4u.live/" target="_blank">
            <img src="../assets/png/download.png" class="download" alt="">
         </a>
      </div>
   </div>
   <br><br>
   <div class="slider1">
      <div id="demo" class="carousel slide" data-ride="carousel">
         <!-- The slideshow -->
         <div class="carousel-inner slider22">
            <div class="carousel-item active">
               <img src="../assets/png/banner1.png" class="img-fluid">
            </div>
            <div class="carousel-item">
               <img src="../assets/png/banner2.png" class="img-fluid">
            </div>
            <div class="carousel-item">
               <img src="../assets/png/banner3.png" class="img-fluid">
            </div>
            <div class="carousel-item">
               <img src="../assets/png/banner4.png" class="img-fluid">
            </div>
            <div class="carousel-item">
               <img src="../assets/png/banner5.png" class="img-fluid">
            </div>
         </div>
         <!-- Left and right controls -->
         <a class="carousel-control-prev" href="#demo" data-slide="prev">
            <!-- <span class="carousel-control-prev-icon"></span> -->
         </a>
         <a class="carousel-control-next" href="#demo" data-slide="next">
            <!-- <span class="carousel-control-next-icon"></span> -->
         </a>
      </div>
   </div>
   <div class="container">
      <div class="row">
         <div class="col-md-4"></div>
         <div class="col-md-4">
            <div class="continar">
               <div class="row">
                  <div class="noticeBar__container">
                     <svg class="svg-icon icon-noticeBarSpeaker" width="25px">
                        <use xlink:href="#icon-noticeBarSpeaker"></use>
                     </svg>
                     <div class="noticeBar__container-body">
                        <div class="noticeBar__container-body-text">Attention ! Attention ! To all JACKPOTCLUB members,</div>
                     </div>
                     <button class="hotIcon">Detail</button>
                  </div>
                  <div data-v-78973569="" data-v-5ab1a830="" class="home_menu">
                     <div data-v-78973569="" class="menu_item "><img data-v-78973569="" class="lottery" alt="" data-origin="../assets/png/VipIcon-1bb97a71.png" src="../assets/png/VipIcon-1bb97a71.png"><span data-v-78973569="" style="font-size: 10px !important;">Lottery</span></div>
                     <div data-v-78973569="" class="menu_item"><img data-v-78973569="" class="popular" alt="" data-origin="../assets/png/gamecategory_202406141604282n54.png" src="../assets/png/gamecategory_202406141604282n54.png"><span data-v-78973569="" style="font-size: 10px !important;">Popular</span></div>
                     <div data-v-78973569="" class="menu_item"><img data-v-78973569="" class="flash" alt="" data-origin="../assets/png/gamecategory_202406141604107pco.png" src="../assets/png/gamecategory_202406141604107pco.png"><span data-v-78973569="" style="font-size: 10px !important;">Mini games</span></div>
                     <div data-v-78973569="" class="menu_item"><img data-v-78973569="" class="video" alt="" data-origin="../assets/png/gamecategory_202406141603574ofs.png" src="../assets/png/gamecategory_202406141603574ofs.png"><span data-v-78973569="" style="font-size: 10px !important;">Casino</span></div>
                     <div data-v-78973569="" class="menu_item"><img data-v-78973569="" class="slot" alt="" data-origin="../assets/png/gamecategory_20240614160346cvin.png" src="../assets/png/gamecategory_20240614160346cvin.png"><span data-v-78973569="" style="font-size: 10px !important;">Slots</span></div>
                     <div data-v-78973569="" class="menu_item"><img data-v-78973569="" class="sport" alt="" data-origin="../assets/png/gamecategory_20240614160323iujm.png" src="../assets/png/gamecategory_20240614160323iujm.png"><span data-v-78973569="" style="font-size: 10px !important;">Sports</span></div>
                     <div data-v-78973569="" class="menu_item"><img data-v-78973569="" class="chess" alt="" data-origin="../assets/png/gamecategory_2024061416155851pl.png" src="../assets/png/gamecategory_2024061416155851pl.png"><span data-v-78973569="" style="font-size: 10px !important;">PVC</span></div>
                     <div data-v-78973569="" class="menu_item"><img data-v-78973569="" class="fish" alt="" data-origin="../assets/png/gamecategory_20240614160251dusd.png" src="../assets/png/gamecategory_20240614160251dusd.png"><span data-v-78973569="" style="font-size: 10px !important;">Fishing</span></div>
                  </div>
               </div>
               <div class="row mt-5 product-conatiner">
                  <div class="col-8" @click="$router.push('wingo')">
                     <h3 class="pt-3 pl-2 text-white"><b>Win Go</b></h3>
                     <b class="pt-3 text-white" style="font-size:12px;">Guess Number</b>
                     <p class="text-white" style="font-size:10px;"><b>Green/Red/Violet to win</b></p>
                  </div>
                  <div class="col-4 flot-right">
                     <img src="../assets/png/lotterycategory_202401021647469isn.png" style="width: 120px; margin-left:-10px; margin-top: -20px;" alt="">
                  </div>
               </div>
               <div class="win_info">
                  <div data-v-6fc57c23="" class="win_item">
                     <div data-v-6fc57c23="" class="left"><img data-v-6fc57c23="" alt="" ata-img="../assets/png/avatar-ea3b8ee9.png" class="" data-origin="14" src="../assets/png/avatar-ea3b8ee9.png" style="object-fit: contain;"><span data-v-6fc57c23="">MemberNHNRVWKW</span></div>
                     <div data-v-6fc57c23="" class="right">Winning amount₹15.52</div>
                  </div>
               </div>
               <div class="row mt-5 product-conatiner-trx">
                  <div class="col-8" @click="$router.push('TrxWin')">
                     <h3 class="pt-3 pl-2 text-white"><b>Trx Win</b></h3>
                     <b class="pt-3 text-white" style="font-size:12px;">Guess Number</b>
                     <p class="text-white" style="font-size:10px;"><b>Green/Red/Violet to win</b></p>
                  </div>
                  <div class="col-4 flot-right">
                     <img src="../assets/png/lotterycategory_20231215033454m1k3.png" style="width: 120px; margin-left:-10px; margin-top: -20px;" alt="">
                  </div>
               </div>
               <div class="win_info">
                  <div data-v-6fc57c23="" class="win_item">
                     <div data-v-6fc57c23="" class="left"><img data-v-6fc57c23="" alt="" ata-img="../assets/png/avatar-ea3b8ee9.png" class="" data-origin="14" src="../assets/png/avatar-ea3b8ee9.png" style="object-fit: contain;"><span data-v-6fc57c23="">MemberNHNRVWKW</span></div>
                     <div data-v-6fc57c23="" class="right">Winning amount₹15.52</div>
                  </div>
               </div>
               <div class="row mt-5 product-conatiner-4d">
                  <div class="col-8">
                     <h3 class="pt-3 pl-2 text-white"><b>K3 Lottery</b></h3>
                     <b class="pt-3 text-white" style="font-size:12px;">Guess Number</b>
                     <p class="text-white" style="font-size:10px;"><b>Green/Red/Violet to win</b></p>
                  </div>
                  <div class="col-4 flot-right">
                     <img src="../assets/png/lotterycategory_202401021647531qcu.png" style="width: 120px; margin-left:-10px; margin-top: -20px;" alt="">
                  </div>
               </div>
               <div class="win_info">
                  <div data-v-6fc57c23="" class="win_item">
                     <div data-v-6fc57c23="" class="left"><img data-v-6fc57c23="" alt="" ata-img="../assets/png/avatar-ea3b8ee9.png" class="" data-origin="14" src="../assets/png/avatar-ea3b8ee9.png" style="object-fit: contain;"><span data-v-6fc57c23="">MemberNHNRVWKW</span></div>
                     <div data-v-6fc57c23="" class="right">Winning amount₹15.52</div>
                  </div>
               </div>
               <div class="row mt-5 product-conatiner-5d">
                  <div class="col-8">
                     <h3 class="pt-3 pl-2 text-white"><b>5D Lottery</b></h3>
                     <b class="pt-3 text-white" style="font-size:12px;">Guess Number</b>
                     <p class="text-white" style="font-size:10px;"><b>Green/Red/Violet to win</b></p>
                  </div>
                  <div class="col-4 flot-right">
                     <img src="../assets/png/lotterycategory_20240102164800645r.png" style="width: 120px; margin-left:-10px; margin-top: -20px;" alt="">
                  </div>
               </div>
               <div class="win_info">
                  <div data-v-6fc57c23="" class="win_item">
                     <div data-v-6fc57c23="" class="left"><img data-v-6fc57c23="" alt="" ata-img="../assets/png/avatar-ea3b8ee9.png" class="" data-origin="14" src="../assets/png/avatar-ea3b8ee9.png" style="object-fit: contain;"><span data-v-6fc57c23="">MemberNHNRVWKW</span></div>
                     <div data-v-6fc57c23="" class="right">Winning amount₹15.52</div>
                  </div>
               </div>
               <!-- wining part -->
               <div data-v-ffb14677="" data-v-5ab1a830="" class="luckyWinners__container">
                  <h1 data-v-ffb14677="">Winning information</h1>
                  <div data-v-ffb14677="" class="luckyWinners__container-wrapper">
                     <div data-v-ffb14677="" style="position: relative;">
                        <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item">
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-img"><img data-v-ffb14677="" class="" data-origin="../assets/png/12-ae12c679.png" src="../assets/png/12-ae12c679.png"></div>
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-info">
                              <h1 data-v-ffb14677="">Mem***QIB</h1>
                           </div>
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-winType"><img data-v-ffb14677="" class="" data-origin="../assets/vendor/vendorlogo_20240614175400clcj.png" src="../assets/vendor/vendorlogo_20240614175400clcj.png"></div>
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-winAmount">
                              <h1 data-v-ffb14677="">Receive ₹1,300.00</h1>
                              <span data-v-ffb14677="">Winning amount</span>
                           </div>
                        </div>
                        <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item">
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-img"><img data-v-ffb14677="" class="" data-origin="../assets/png/17-bedde42f.png" src="../assets/png/17-bedde42f.png"></div>
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-info">
                              <h1 data-v-ffb14677="">Mem***TVS</h1>
                           </div>
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-winType"><img data-v-ffb14677="" class="" data-origin="../assets/vendor/vendorlogo_2024010216544959ef.png" src="../assets/vendor/vendorlogo_2024010216544959ef.png"></div>
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-winAmount">
                              <h1 data-v-ffb14677="">Receive ₹12,800.00</h1>
                              <span data-v-ffb14677="">Winning amount</span>
                           </div>
                        </div>
                        <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item">
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-img"><img data-v-ffb14677="" class="" data-origin="../assets/png/6-7c7f5203.png" src="../assets/png/6-7c7f5203.png"></div>
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-info">
                              <h1 data-v-ffb14677="">Mem***KIE</h1>
                           </div>
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-winType"><img data-v-ffb14677="" class="" data-origin="../assets/vendor/lotterycategory_20240102164806fiwd.png" src="../assets/vendor/lotterycategory_20240102164806fiwd.png"></div>
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-winAmount">
                              <h1 data-v-ffb14677="">Receive ₹1,000.00</h1>
                              <span data-v-ffb14677="">Winning amount</span>
                           </div>
                        </div>
                        <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item">
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-img"><img data-v-ffb14677="" class="" data-origin="../assets/png/8-ea087ede.png" src="../assets/png/8-ea087ede.png"></div>
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-info">
                              <h1 data-v-ffb14677="">Mem***YSX</h1>
                           </div>
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-winType"><img data-v-ffb14677="" class="" data-origin="https://ossimg.forpicStore777.top/rajalottery/vendorlogo/vendorlogo_20240614175400clcj.png" src="https://ossimg.forpicStore777.top/rajalottery/vendorlogo/vendorlogo_20240614175400clcj.png"></div>
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-winAmount">
                              <h1 data-v-ffb14677="">Receive ₹568.00</h1>
                              <span data-v-ffb14677="">Winning amount</span>
                           </div>
                        </div>
                        <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item">
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-img"><img data-v-ffb14677="" class="" data-origin="../assets/png/14-a397ff6b.png" src="../assets/png/14-a397ff6b.png"></div>
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-info">
                              <h1 data-v-ffb14677="">Mem***EXW</h1>
                           </div>
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-winType"><img data-v-ffb14677="" class="" data-origin="https://ossimg.forpicStore777.top/rajalottery/lotterycategory/lotterycategory_20240102164806fiwd.png" src="https://ossimg.forpicStore777.top/rajalottery/lotterycategory/lotterycategory_20240102164806fiwd.png"></div>
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-winAmount">
                              <h1 data-v-ffb14677="">Receive ₹295.96</h1>
                              <span data-v-ffb14677="">Winning amount</span>
                           </div>
                        </div>
                        <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item">
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-img"><img data-v-ffb14677="" class="" data-origin="../assets/png/3-abfcc056.png" src="../assets/png/3-abfcc056.png"></div>
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-info">
                              <h1 data-v-ffb14677="">Mem***WEV</h1>
                           </div>
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-winType"><img data-v-ffb14677="" class="" data-origin="https://ossimg.forpicStore777.top/rajalottery/lotterycategory/lotterycategory_20240102164806fiwd.png" src="https://ossimg.forpicStore777.top/rajalottery/lotterycategory/lotterycategory_20240102164806fiwd.png"></div>
                           <div data-v-ffb14677="" class="luckyWinners__container-wrapper__item-winAmount">
                              <h1 data-v-ffb14677="">Receive ₹29.40</h1>
                              <span data-v-ffb14677="">Winning amount</span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <!-- wining end -->
            </div>
            <br>
            <br>
            <br>
            <br>
         </div>
         <div class="col-md-4"></div>
      </div>
   </div>
    <div class="footer-nav" >
       <div class="footer-nav-item active" @click="$router.push('home')">
          <svg data-v-6ab3f23e="" class="svg-icon icon-home">
             <use xlink:href="#icon-home"></use>
          </svg>
          <!--v-if--><span data-v-6ab3f23e="">Home</span>
       </div>
       <div data-v-6ab3f23e="" class="footer-nav-item" @click="$router.push('activity')">
          <svg data-v-6ab3f23e="" class="svg-icon icon-activity">
             <use xlink:href="#icon-activity"></use>
          </svg>
          <!--v-if--><span data-v-6ab3f23e="">Activity</span>
       </div>
       <div data-v-6ab3f23e="" class="footer-nav-item" @click="$router.push('promotion')">
          <svg data-v-6ab3f23e="" class="svg-icon icon-promotion">
             <use xlink:href="#icon-promotion"></use>
          </svg>
          <div data-v-6ab3f23e="" class="promotionBg"></div>
          <span data-v-6ab3f23e="">Promotion</span>
       </div>
       <div data-v-6ab3f23e="" class="footer-nav-item" @click="$router.push('wallet')">
          <svg data-v-6ab3f23e="" class="svg-icon icon-wallet">
             <use xlink:href="#icon-wallet"></use>
          </svg>
          <!--v-if--><span data-v-6ab3f23e="">Wallet</span>
       </div>
       <div data-v-6ab3f23e="" class="footer-nav-item" @click="$router.push('Mine')">
          <svg data-v-6ab3f23e="" class="svg-icon icon-main">
             <use xlink:href="#icon-main"></use>
          </svg>
          <!--v-if--><span data-v-6ab3f23e="">Account</span>
       </div>
    </div>
</template>
<script>
   export default {
       name: "HomeView",
     
   }
   
</script>